<template>
  <div class="boxDiv">
    <div class="topic">
      <div class="checkBtn">
        <el-button type="primary" round @click="changeType">综合搜索</el-button>
      </div>
      <div class="titleImg">
        <span class="titleName">可萌数据系统</span>
        <el-image
          class="elImg"
          src="https://suezp.cn/server/kmProfile.png"
          fit="fill"></el-image>
      </div>
    </div>
    <div class="menuDiv">
      <ul>
        <li class="menuLi" style="background:linear-gradient(135deg,#622774,#c53364)" @click="goTenthYearList">十周年用户</li>
        <li class="menuLi" style="background:linear-gradient(135deg,#e8d07a,#5312d6);" @click="goNineYearList">九周年用户</li>
        <li class="menuLi" style="background:linear-gradient(135deg,#65799b,#5e2563);" @click="goEightYearList">八周年用户</li>
        <!-- <li class="menuLi" @click="goSevenYearList">七周年用户</li> -->
        <li class="menuLi" @click="goSinglePart">七周年单次套系</li>
        <li class="menuLi inner" @click="goInnerSinglePart">店内单次套系</li>
        <li class="menuLi inner" @click="goInnerGrowUp">店内成长套系</li>
        <li class="menuLi inner" @click="goProduction">到件产品管理</li>
        <li class="menuLi inner vip" @click="goVipList">会员管理</li>
        <br />
        <h2>vip服务</h2> <br />
        <li class="menuLi inner vip-service"  @click="goVipServe('5k')">5千以上</li>
        <li class="menuLi inner vip-service"  @click="goVipServe('3k')">3~4千</li>
        <li class="menuLi inner vip-service" @click="goVipServe('2k')">1~2千</li>
      </ul>
    </div>
    <div class="footer">
      <span @click="goBeian">闽ICP备19006430号-2</span>
    </div>

    <!-- web 全局搜索 -->
    <el-dialog width="80%" top="5vh" custom-class="dialogBox" :visible.sync="showAllSearch" >
      <div slot="title" class="allSearch-header">
        <el-form inline style="margin-top: 15px;margin-left: 35px;">
          <el-form-item label="主套系">
            <el-select
              v-model="formInline.mainPlanDone"
              placeholder="请选择"
              clearable
              size="small"
              @clear="search"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附加套系">
            <el-select
              v-model="formInline.subPlanDone"
              placeholder="请选择"
              clearable
              size="small"
              @clear="search"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <span class="tips">(该功能目前仅九周年支持)</span> -->
          </el-form-item>
        </el-form>
        <div class="searchBox">
          <input type="search" v-model="keywords" @keydown.enter="search" placeholder="输入想要搜索的内容......(按下回车搜索)">
          <i class="el-icon-search" style="background:#0000006b;font-weight:600;" @click="search" ></i>
        </div>
      </div>
      <div class="allSearchBox" >
        <el-table
          stripe
          border
          v-loading="tableLoading"
          :data="tableData"
          max-height="600"
          style="width: 100%;"
          aria-setsize="medium">
          <el-table-column
            prop="parentName"
            align="center"
            label="家长姓名"
            width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.parentName"> {{ scope.row.parentName }} </span>
              <span v-else-if="scope.row.faName"> {{ scope.row.faName }} </span>
              <span v-else-if="scope.row.momName"> {{ scope.row.momName }} </span>
              <span v-else> - </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="宝宝姓名"
            width="120">
          </el-table-column>
          <el-table-column
            prop="phone"
            align="center"
            width="130"
            label="手机号">
          </el-table-column>
          <el-table-column
            prop="orderPrice"
            align="center"
            width="130"
            label="套系金额">
          </el-table-column>
          <el-table-column align="center" prop="status" label="套系情况" width="180">
            <template slot-scope="scope">
              <div>
                <div
                  :class="[
                    { 'redFont': scope.row.mainPlanDone == 0 },
                    { 'blueFont': scope.row.mainPlanDone == '' },
                    { 'greenFont': scope.row.mainPlanDone == 1 },
                  ]"
                >
                  主套系
                  <i class="el-icon-success" v-if="scope.row.mainPlanDone == 1"></i>
                  {{
                    scope.row.mainPlanDone == 0 
                      ? "未完成"
                      : scope.row.mainPlanDone == ''|| scope.row.mainPlanDone === undefined
                      ? "-"
                      : scope.row.mainPlanDone == 1
                      ? "已完成"
                      : ""
                  }}
                </div>
                <div
                  :class="[
                    { 'redFont': scope.row.subPlanDone == 0 },
                    { 'blueFont': scope.row.subPlanDone == '' },
                    { 'greenFont': scope.row.subPlanDone == 1 },
                  ]"
                >
                  附加套系
                  <i class="el-icon-success" v-if="scope.row.subPlanDone == 1"></i>
                  {{
                    scope.row.subPlanDone == 0 
                      ? "未完成"
                      : scope.row.subPlanDone == ''|| scope.row.subPlanDone === undefined
                      ? "-"
                      : scope.row.subPlanDone == 1
                      ? "已完成"
                      : ""
                  }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="wallet"
            label="vip余额"
            width="140"
            align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.wallet?scope.row.wallet:'-' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="comment"
            align="center"
            width="auto"
            label="备注">
          </el-table-column>
          <el-table-column
            prop="from"
            label="数据来源"
            width="140"
            align="center">
          </el-table-column>
          <el-table-column align="center" label="操作" width="130">
            <template slot-scope="scope">
              <div @click="goDataPage(scope.row)">
                <el-button size="small" type="primary">进入目标页面</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total,sizes, prev, pager, next"
          :current-page="pages.page * 1"
          :total="pages.total"
          :page-size="pages.pageSize * 1"
          style="margin-top: 20px"
          :page-sizes="[100]"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </el-dialog>

    <!-- H5 全局搜索 -->
    <van-popup
      v-model="showH5Search"
      closeable
      position="bottom"
      round
      :style="{ height: '93%' }"
      @close="showH5Search=false"
    >
      <div class="h5SearchBox" >
        <div class="title">综合搜索</div>
        <form action="javascript:return true">
          <van-search
          v-model="keywords"
          show-action
          placeholder="请输入搜索关键词"
          @search="search"
        >
          <template #action>
            <div @click="search">搜索</div>
          </template>
        </van-search>
        </form>
        <van-list
          v-if="!tableLoading"
          class="litBox"
          v-model="tableLoading"
          :finished="true"
          finished-text="没有更多了"
        >
          <van-cell v-for="(item,index) in tableData" :key="item.name+index" @click="goDataPage(item)">
            <div class="outsideCell" >
              <div class="cellStyle" >
                <span class="insideTitle" >宝宝姓名：</span>
                <van-tag type="primary" size="large">{{item.name?item.name:'-'}}</van-tag>
              </div>
              <div class="cellStyle" >
                <span class="insideTitle" >家长姓名:</span>
                <span v-if="item.parentName"> {{ item.parentName }} </span>
                <span v-else-if="item.faName"> {{ item.faName }} </span>
                <span v-else-if="item.momName"> {{ item.momName }} </span>
                <span v-else> - </span>
              </div>
              <div class="cellStyle" >
                <span class="insideTitle">手机号:</span>{{item.phone?item.phone:'-'}}
              </div>
              <div class="cellStyle" >
                <span class="insideTitle">套系金额:</span>{{item.orderPrice?item.orderPrice:'-'}}
              </div>
              <div >
                <span class="insideTitle">备注:</span>{{item.comment?item.comment:'-'}}
              </div>
              <div class="fromCell" >数据来源:<br/>{{item.from}}</div>
            </div>
          </van-cell>
        </van-list>
        <van-loading style="margin:100px auto 0;" v-else type="spinner" color="#1989fa" ></van-loading>
      </div>
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "kmSystemCenter",
  components: {},
  data() {
    return {
      pages: {
        page: 1,
        pageSize: 100,
        total: 0,
      },
      showAllSearch: false,
      tableData: [],
      screenWidth: null,
      keywords: '',
      tableLoading: false,
      showH5Search: false,
      options2: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "已完成",
        },
        {
          value: 0,
          label: "未完成",
        },
      ],
      formInline: {
        mainPlanDone: "",
        subPlanDone: "",
      },
    };
  },
  created() {
    this.checkLogin();
    this.screenWidth = document.documentElement.clientWidth;
  },
  methods: {
    checkLogin() {
      axios
        .get(
          this.$baseUrl +
            "/singlePart?" +
            "page=" +
            this.pages.page +
            "&pageNum=" +
            this.pages.pageNum +
            "&name=" +
            "&phone=" +
            "&keyWord="
        )
        .then(() => {});
    },
    goVipList() {
      if (this.screenWidth < 550) {
        return;
      }
      this.$router.push("/vipList");
    },
    goSevenYearList() {
      if (this.screenWidth < 550) {
        this.$router.push("/h5ConsumerList");
        return;
      }
      this.$router.push("/kmConsumerList");
    },
    // vip服务用户
    goVipServe(type) {
      if (this.screenWidth < 550 ) {
        this.$message({
          showClose: true,
          message: "功能研发中",
          type: "warning",
        });
        return;
      }
      this.$router.push(`/vipServeUser?type=${type}`);
    },
    goTenthYearList() {
      if (this.screenWidth < 550 ) {
        this.$message({
          showClose: true,
          message: "功能研发中",
          type: "warning",
        });
        return;
      }
      this.$router.push("/tenthConsumerList");
    },
    goNineYearList() {
      if (this.screenWidth < 550 ) {
        this.$message({
          showClose: true,
          message: "功能研发中",
          type: "warning",
        });
        return;
      }
      this.$router.push("/nineConsumerList");
    },
    goEightYearList() {
      if (this.screenWidth < 550 ) {
        this.$message({
          showClose: true,
          message: "功能研发中",
          type: "warning",
        });
        return;
      }
      this.$router.push("/eightConsumerList");
    },
    goSinglePart() {
      if (this.screenWidth < 550) {
        this.$router.push("/h5SinglePartList");
        return;
      }
      this.$router.push("/singlePartList");
    },
    goInnerSinglePart() {
      if (this.screenWidth < 550) {
        this.$router.push("/h5InnerSinglePartList");
        return;
      }
      this.$router.push("/innerSinglePartList");
    },
    goInnerGrowUp() {
      if (this.screenWidth < 550 ) {
        this.$router.push("/h5InnerGrowUpList");
        return;
      }
      this.$router.push("/innerGrowUpList");
    },
    goProduction() {
      if (this.screenWidth < 550 ) {
        this.$message({
          showClose: true,
          message: "功能研发中",
          type: "warning",
        });
        return;
      }
      this.$router.push("/productionList");
    },
    goBeian() {
      window.open("http://www.beian.gov.cn/");
    },
    search() {
      this.pages.page = 1;
      this.getdata();
    },
    getdata() {
      //  /innerGrowUp 店内成长
      //  /innerSinglePart  店内单次套系
      //  /singlePart       七周年单次套系
      this.tableLoading = true;
      this.tableData = [];
      axios.get(this.$baseUrl+'/allSearch?keyword='+this.keywords+'&page='+this.pages.page + "&mainPlanDone=" +
        this.formInline.mainPlanDone +
        "&subPlanDone=" +
        this.formInline.subPlanDone
      ).then(res=>{
        this.tableData = res.data.list;
        this.pages.total = res.data.pages.total * 1;
        setTimeout(() => {
          this.tableLoading = false;
        }, 200);
      })
    },
    changePage(val) {
      this.pages.page = val * 1;
      this.getdata();
    },
    changeType() {
      if(this.screenWidth < 500) {
        this.showH5Search = true;
      } else {
        this.showAllSearch = true;
      }
    },
    goDataPage(item) {
      if(this.screenWidth < 500) {
        this.$router.push({
          path: '/h5' + item.router.replace('/',''),
          query: {
            keyword:this.keywords,
            name: item.name
          }
        })
      } else {
        this.$router.push({
          path: item.router,
          query: {
            keyword:this.keywords,
            name: item.name
          }
        })
        // 路由 keep-alive 蒙层 问题
        try {
          document.querySelector('.v-modal').style.zIndex = -1;
        } catch(err) {
          console.log(err,'蒙尘问题')
        }
      }
    }
  },
};
</script>
<style scoped lang="less">
.topic {
  display: flex;
  padding-top: 10px;
  letter-spacing: 1px;
  .checkBtn {
    height: 45px;
    flex: 1;
    padding-left: 70px;
    box-sizing: border-box;
  }
  .titleImg {
    text-align: right;
    box-sizing: border-box;
    padding-right: 10px;
    height: 45px;
    .elImg {
      float: right;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
  .titleName {
    height: 100%;
    line-height: 44px;
    text-align: center;
    float: right;
    letter-spacing: 1px;
    font-size: 15px;
    margin-left: 10px;
    color:#ddd4d4;
  }
}
.boxDiv {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  background: url("https://suezp.cn/server/kmbj.png") no-repeat;
  background-size: cover;
}
.menuDiv {
  margin-top: 40px;
  padding: 30px 50px 20px;
  box-sizing: border-box;

  h2 {
    margin: 30px 0 10px 10px;
    padding-left: 20px;
    color: #fff;
  }
  .menuLi {
    margin: 20px;
    display: inline-block;
    padding: 10px 20px 10px;
    text-align: center;
    height: 120px;
    min-width: 120px;
    line-height: 110px;
    border-radius: 10px;
    box-shadow: -2px 3px 5px rgb(0 0 0 / 60%);
    border: 1px solid #00000025;
    transition: all 0.2s ease-in-out 0s;
    cursor: pointer;
    font-size: 18px;
    color: #ddd4d4;
    background: #2d5a635c;
  }
  .inner {
    background: #1a0033;
    box-shadow: -1px 2px 5px rgb(0 0 0 / 60%);;
  }
  .vip {
    background-image: linear-gradient(135deg,#fce38a,#f38181);
    color: #fff;
  }
  .vip-service {
    background: linear-gradient(135deg,#abdcff,#0396ff);
    font-weight: bold;
    font-size: 28px;
    color: #000;
  }
  .menuLi:hover {
    box-shadow: 0px 3px 10px rgb(214, 202, 202);
  }
  .allSearch {
    background-color: #2c80c5;
  }
}
.formFooter {
  font-size: 16px;
  margin-top: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}
.footer {
  width: 100%;
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  span {
    cursor: pointer;
  }
}

.allSearchBox {
  height: 650px;
  // overflow-y: auto;
  .redFont {
    font-weight: 500;
    color: #492525;
  }
  .blueFont {
    font-weight: 500;
    color: #2e62cd;
  }
  .greenFont {
    font-weight: 500;
    color: #349e69;
  }
}
.allSearch-header {
  .searchBox {
    display: flex;
    margin: 0 auto;
    height: 50px;
    width: 84%;
    min-width: 500px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: rgb(250, 250, 250);
    input {
      flex: 1;
      padding: 0 20px;
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
      font-size: 16px;
      background-color: rgba(0, 0, 0, 0);
    }
    i {
      height: 100%;
      width: 50px;
      color: #0072c9;
      line-height: 50px;
      text-align: center;
      font-size: 22px;
      cursor: pointer;
    }
  }
}

.el-row {
  margin-bottom: 20px;
  background: #a3b4c9;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 46px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

// 兼容H5
@media screen and (max-width: 540px) {
  .menuDiv {
    padding: 0px 0px 20px;
    box-sizing: border-box;
    text-align: center;
    overflow-x: hidden;
    margin-top: 40px;
    .menuLi {
      margin: 20px 10px 0 0!important;
      display: inline-block;
      padding: 10px 20px 10px;
      height: 40px;
      // width: 90%;
      line-height: 40px;
      border-radius: 5px;
      box-shadow: 0px 3px 5px rgb(0 0 0 / 60%);
      border: 1px solid #00000025;
      transition: all 0.2s ease-in-out 0s;
      cursor: pointer;
      font-size: 16px;
      color: #ddd4d4;
      background: #2d5a635c;
    }
    .menuLi:hover {
      box-shadow: 0px 3px 10px rgb(0 0 0 / 70%);
    }
    .inner {
      background: #1a0033;
      box-shadow: 0px 1px 5px rgba(214, 202, 202, 0.555);
    }
  }
  .checkBtn {
    padding-left: 20px!important;
  }
}
.h5SearchBox {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height:100%;
  width:100%;
  .title {
    height:45px;
    line-height: 45px;
    width: 100%;
    text-align: center;
    background-color: #ebedef;
  }
  .litBox {
    flex: 1;
    overflow-y: auto;
    background: #fff;
    .outsideCell {
      position: relative;
    }
    .cellStyle {
      width: 226px;
      font-size: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .insideTitle {
      display: inline-block;
      width: 76px;
    }
    .fromCell {
      position: absolute;
      border: 1px solid black;
      min-width: 105px;
      padding: 5px;
      border-radius: 5px;
      right: 0;
      top: 0;
    }
  }
}
/deep/.van-popup__close-icon {
  color: #000;
}
/deep/.van-cell::after {
  border-bottom:0px;
  height: 2px;
  background: repeating-linear-gradient(
  -45deg
  , #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
}
</style>